<template>
    <div v-if="!loading" class="form-adming row">
        <div class="col-md-12">
            <p class="form-admin__subtitle">
                <span class="fas fa-user m-1"></span>
                Profile
            </p>
        </div>
        <div v-if="!isHidden('first_name')" class="col-md-6 mb-4">
            <ui-input
                id="firstName"
                name="firstName"
                label="First name"
                type="text"
                v-model:value="formData.first_name"
                :field="v$.formData.first_name"
                :disabled="isDisabled('first_name')"
            />
        </div>
        <div v-if="!isHidden('last_name')" class="col-md-6 mb-4">
            <ui-input
                id="lastName"
                name="lastName"
                label="Last name"
                type="text"
                v-model:value="formData.last_name"
                :field="v$.formData.last_name"
                :disabled="isDisabled('last_name')"
            />
        </div>
        <div v-if="!isHidden('email')" class="col-md-6 mb-4">
            <ui-input
                id="email"
                name="email"
                label="Email"
                type="email"
                v-model:value="formData.email"
                :field="v$.formData.email"
                :disabled="isDisabled('email')"
            />
        </div>
        <div v-if="!isHidden('phone')" class="col-md-6 mb-4">
            <ui-input
                id="phone"
                name="phone"
                label="Phone"
                type="text"
                v-model:value="formData.phone"
                :field="v$.formData.phone"
                :disabled="isDisabled('phone')"
            />
        </div>
        <div v-if="!isHidden('role')" class="col-md-6 mb-4">
            <ui-select
                id="role"
                name="role"
                class="test-class w-100"
                label="Role"
                option-label="key"
                option-value="value"
                v-model:value="formData.role"
                :options="roles"
                :field="v$.formData.role"
                :disabled="isDisabled('role')"
            />
        </div> 
        <div v-if="!isHidden('profile')" class="col-md-6 mb-4">
            <ui-select
                id="profile"
                name="profile"
                class="test-class w-100"
                label="Profile"
                option-label="key"
                option-value="value"
                v-model:value="formData.profile"
                :options="profiles"
                :field="v$.formData.profile"
                :disabled="isDisabled('profile')"
                @change="handleScopes()"
            />
        </div>      
        <div class="col-md-12">
            <p class="form-admin__subtitle">
                <span class="fas fa-map-marker-alt m-1"></span>
                Address
            </p>
        </div>
        <div v-if="!isHidden('country')" class="col-md-6 mb-4">
            <ui-select 
                id="country"
                name="country"
                type="select"
                class="test-class w-100"
                label="Country"
                option-label="name"
                option-value="code"
                v-model:value="formData.address.country"
                :options="countries"
                :disabled="isDisabled('country')"
                @change="getCountry()" 
            />
        </div>
        <div v-if="!isHidden('street')" class="col-md-6 mb-4">
            <ui-input
                id="street"
                name="street"
                label="Street"
                type="text"
                v-model:value="formData.address.street"
                :field="v$.formData.street"
                :disabled="isDisabled('street')"
            />
        </div>
        <div v-if="!isHidden('external_number')" class="col-md-6 mb-4">
            <ui-input
                id="externalNumber"
                name="externalNumber"
                label="External number"
                type="externalNumber"
                v-model:value="formData.address.external_number"
                :field="v$.formData.external_number"
                :disabled="isDisabled('external_number')"
            />
        </div>
        <div v-if="!isHidden('postal_code')" class="col-md-6 mb-4">
            <ui-input
                id="postalCode"
                name="postalCode"
                label="Postal code"
                type="text"
                v-model:value="formData.address.postal_code"
                :field="v$.formData.postal_code"
                :disabled="isDisabled('postal_code')"
            />
        </div>
        <div v-if="!isHidden('first_name')" class="col-md-6 mb-4">
            <ui-input
                id="locale"
                name="locale"
                label="Locale"
                type="text"
                v-model:value="formData.address.locale"
                :field="v$.formData.locale"
                :disabled="isDisabled('locale')"
            />
        </div>
        <div v-if="!isHidden('district')" class="col-md-6 mb-4">
            <ui-input
                id="district"
                name="district"
                label="District"
                type="text"
                v-model:value="formData.address.district"
                :field="v$.formData.district"
                :disabled="isDisabled('district')"
            />
        </div>
        <div v-if="!isHidden('active')" class="col-md-12">
            <div class="form-admin__switch">
                <ui-switch
                    id="active"
                    name="active"
                    :disabled="isDisabled('active')"
                    v-model:value="formData.active"
                />
                <label for="active" class="form-check-label">
                    {{ formData.active ? 'Active' : 'Inactive'}}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import UiInput from '@/components/ui/Input.vue';
import UiSelect from '@/components/ui/Select.vue';
import UiSwitch from '@/components/ui/Switch.vue';

import admin_form_mixin from '@/mixins/admin-form.mixin';

export default {
    name: 'FormAdmin',
    components: { UiInput, UiSelect, UiSwitch },
    mixins: [admin_form_mixin],
    props: {
        defaultData: {
            type: Object,
            default: () => {},
            required: false,
        },
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            roles: [
                {
                    key: 'Superadmin',
                    value: 'superadmin',
                },
                {
                    key: 'Admin',
                    value: 'admin',
                },
                {
                    key: 'User',
                    value: 'user',
                },
            ],
            loading: false,
            countries: [],
        };
    },
    async mounted() {
        this.getCountry();
        this.loadDefaulData();
    },
    methods: {
        loadDefaulData() {
            if (!this.defaultData) return;
            for(const inputName in this.formData) {
                if (this.defaultData[inputName]) {
                    this.formData[inputName] = this.defaultData[inputName];
                }
            }
        },
        async getCountry() {
            const { data } = await this.$axios.get(`/_/catalogs/countries`);
            this.countries = data;
		},
        isDisabled(inputName) {
            return this.disabledInputs.includes(inputName);
        },
        isHidden(inputName) {
            return this.hiddenInputs.includes(inputName);
        },
    },
}
</script>

<style lang="scss">
.form-admin{
    &__subtitle {
        color: $ecart-secondary-500;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
    }

    &__switch {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }
}
</style>